var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-start": "" } },
    [
      _c("v-flex", { attrs: { xs12: "", "pt-4": "" } }, [
        _c("h3", [_vm._v("Shift Details")]),
      ]),
      _c(
        "v-flex",
        { attrs: { xs12: "", "align-self-center": "" } },
        [
          _c(
            "v-form",
            { ref: "form", attrs: { "lazy-validation": "" } },
            _vm._l(_vm.getShifts, function (item, index) {
              return _c(
                "v-layout",
                {
                  key: "shift-" + index,
                  attrs: { wrap: "", "justify-start": "", "py-2": "" },
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs2: "", lg1: "" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: item.name, dense: "", ripple: false },
                        model: {
                          value: item.enabled,
                          callback: function ($$v) {
                            _vm.$set(item, "enabled", $$v)
                          },
                          expression: "item.enabled",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs2: "", "px-2": "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Name",
                          dense: "",
                          outlined: "",
                          disabled: !item.enabled,
                          rules: item.enabled ? [_vm.RULES.REQUIRED] : [],
                        },
                        model: {
                          value: item.name,
                          callback: function ($$v) {
                            _vm.$set(item, "name", $$v)
                          },
                          expression: "item.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs2: "", "px-2": "" } },
                    [
                      _c("AppTimePicker", {
                        attrs: {
                          label: "Start Time",
                          disabled: !item.enabled,
                          rules: item.enabled ? [_vm.RULES.REQUIRED] : [],
                        },
                        model: {
                          value: item.start_time,
                          callback: function ($$v) {
                            _vm.$set(item, "start_time", $$v)
                          },
                          expression: "item.start_time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs2: "", "px-2": "" } },
                    [
                      _c("AppTimePicker", {
                        attrs: {
                          label: "End Time",
                          disabled: !item.enabled,
                          rules: item.enabled ? [_vm.RULES.REQUIRED] : [],
                        },
                        model: {
                          value: item.end_time,
                          callback: function ($$v) {
                            _vm.$set(item, "end_time", $$v)
                          },
                          expression: "item.end_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "", lg8: "", "text-right": "" } },
        [
          _c("AppButton", {
            attrs: { "custom-class": "pa-5" },
            on: { submit: _vm.saveShift },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [_vm._v(" Save Shift ")]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }