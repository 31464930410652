<template xmlns:AppTimePicker="http://www.w3.org/1999/html">
  <v-layout wrap justify-start>
    <v-flex xs12 pt-4>
      <h3>Shift Details</h3>
    </v-flex>
    <v-flex xs12 align-self-center>
      <v-form ref="form" lazy-validation>
        <v-layout
          wrap
          justify-start
          v-for="(item, index) in getShifts"
          :key="'shift-' + index"
          py-2
        >
          <v-flex xs2 lg1>
            <v-checkbox
              :label="item.name"
              dense
              :ripple="false"
              v-model="item.enabled"
            ></v-checkbox>
          </v-flex>
          <v-flex xs2 px-2>
            <v-text-field
              label="Name"
              dense
              outlined
              v-model="item.name"
              :disabled="!item.enabled"
              :rules="item.enabled ? [RULES.REQUIRED] : []"
            />
          </v-flex>
          <v-flex xs2 px-2>
            <AppTimePicker
              label="Start Time"
              v-model="item.start_time"
              :disabled="!item.enabled"
              :rules="item.enabled ? [RULES.REQUIRED] : []"
            />
          </v-flex>
          <v-flex xs2 px-2>
            <AppTimePicker
              label="End Time"
              v-model="item.end_time"
              :disabled="!item.enabled"
              :rules="item.enabled ? [RULES.REQUIRED] : []"
            />
          </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
    <v-flex xs12 lg8 text-right>
      <AppButton @submit="saveShift" custom-class="pa-5">
        <template #content> Save Shift </template>
      </AppButton>
    </v-flex>
  </v-layout>
</template>

<script>
import AppTimePicker from "@/components/AppTimePicker.vue";
import { RULES } from "@/libs/helper/components/rules";

export default {
  name: "Shift",
  components: { AppTimePicker },
  data: () => ({
    RULES,
    shifts: [],
  }),
  computed: {
    getShifts: {
      get() {
        return this.shifts;
      },
      set(val) {
        this.shifts = val;
      },
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch("hospital/getShifts").then((res) => {
        this.shifts = res.shifts;
      });
    },
    saveShift() {
      if (!this.$refs.form.validate()) return;
      const data = { shifts: this.shifts };
      this.$store.dispatch("hospital/editShifts", data).then((res) => {
        console.log(res);
      });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>
