var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      ref: "dialog",
      attrs: { "return-value": _vm.curValue, persistent: "", width: "290px" },
      on: {
        "update:returnValue": function ($event) {
          _vm.curValue = $event
        },
        "update:return-value": function ($event) {
          _vm.curValue = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        label: _vm.label,
                        "prepend-icon": "mdi-clock-time-four-outline",
                        readonly: "",
                      },
                      model: {
                        value: _vm.curValue,
                        callback: function ($$v) {
                          _vm.curValue = $$v
                        },
                        expression: "curValue",
                      },
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _vm.menu
        ? _c(
            "v-time-picker",
            {
              attrs: { "full-width": "", format: "24hr", color: "primary" },
              model: {
                value: _vm.curValue,
                callback: function ($$v) {
                  _vm.curValue = $$v
                },
                expression: "curValue",
              },
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.menu = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.dialog.save(_vm.curValue)
                    },
                  },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }