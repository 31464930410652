<template>
  <v-dialog
    ref="dialog"
    v-model="menu"
    :return-value.sync="curValue"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="curValue"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="curValue"
      full-width
      format="24hr"
      color="primary"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(curValue)">
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
  <!--  <v-menu-->
  <!--    ref="menu"-->
  <!--    v-model="menu"-->
  <!--    :close-on-content-click="false"-->
  <!--    :return-value.sync="curValue"-->
  <!--    transition="scale-transition"-->
  <!--    offset-y-->
  <!--    max-width="290px"-->
  <!--    min-width="290px"-->
  <!--  >-->
  <!--    <template v-slot:activator="{ on, attrs }">-->
  <!--      <v-text-field-->
  <!--        v-model="curValue"-->
  <!--        :label="label"-->
  <!--        readonly-->
  <!--        :disabled="disabled"-->
  <!--        dense-->
  <!--        outlined-->
  <!--        v-bind="attrs"-->
  <!--        v-on="on"-->
  <!--        :rules="rules"-->
  <!--      ></v-text-field>-->
  <!--    </template>-->
  <!--    <v-time-picker-->
  <!--      color="primary"-->
  <!--      v-if="menu"-->
  <!--      v-model="curValue"-->
  <!--      full-width-->
  <!--      format="24hr"-->
  <!--      @click:minute="$refs.menu.save(curValue)"-->
  <!--    ></v-time-picker>-->
  <!--  </v-menu>-->
</template>

<script>
export default {
  name: "AppTimePicker",
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "Time",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    curValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data: () => ({
    menu: false,
  }),
};
</script>

<style scoped lang="scss">
@import "./../assets/style/partials/_variables.scss";
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label.v-label--is-disabled {
  font-weight: 400;
  color: $grey !important;
}
</style>
